import React, {useState, useRef} from 'react'
import validator from 'validator'
import LoadingButton from '@mui/lab/LoadingButton'
import { Stack, TextField, InputLabel, Select, MenuItem, FormControl, Typography, CircularProgress} from '@mui/material'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faTriangleExclamation, faCircleExclamation} from '@fortawesome/free-solid-svg-icons'
import './form.css'

const Form = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [formValidate, setFormValidate] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(0)
  const [didSubmit, setDidSubmit] = useState(false)
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    serviceType: '',
    request: ''
  })
  const selectRef = useRef(null)
  const [submitMessage, setSubmitMessage] = useState('')
  const [errors, setErrors] = useState({})
  
  const validateFormData = () => {
    const newErrors = {}

    if(!validator.isEmail(formData.email || '')) {
      newErrors.email = 'Invalid email'
    }
    if(!validator.isMobilePhone(formData.phone || '', 'any', {strictMode: false})) {
      newErrors.phone = 'Invalid phone number'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }
  
  const handleChange = (e) => {
    const {name, value} = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  
  const handleJobRequestOption = (e) => {
    const optionSelection = e.target.value
    setFormData({...formData, serviceType: optionSelection})
  }

  const formBoarderValidated = {
    border: submitSuccess === 201 ? '2px solid #00FF00' : '2px solid #FF0000',
    borderRadius: '8px'
  }

  const textColorValidation = {
    color: submitSuccess === 201 ? '#00FF00' : '#FF0000',
    marginLeft: '8px',
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const isValid = validateFormData()

    try {
      if(isValid && formData.fullName && formData.email && formData.phone && formData.address && formData.serviceType && formData.request) {
        setFormValidate(false)
        setIsLoading(true)
        const formDataObject = {
          fullName: formData.fullName,
          email: formData.email,
          phone: formData.phone,
          address: formData.address,
          serviceType: formData.serviceType,
          request: formData.request
        }
        
        const response = await fetch(process.env.REACT_APP_PROD_URL, {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formDataObject),
        })
        
        if(!response.ok) {
          const errorData = await response.json()
          throw new Error(`Failed to create record: ${errorData.error}`)
        }
        
        const responseData = await response.json()
        
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          address: '',
          serviceType: '',
          request: '',
        })
        setSubmitSuccess(response.status)
        setSubmitMessage(responseData.message)
        setDidSubmit(true)
        setIsLoading(false)
      } else {
        setFormValidate(true)
      }
      
    } catch(error) {
      setIsLoading(false)
      setSubmitMessage(error)
      console.error('Failed to create client request record', error)
    }
  }
  const submissionPassOrFailed = submitSuccess === 201 ? "validate-success" : "validate-failed"
  const submissionPassOrFailedMessage = submitSuccess === 201 ? submitMessage : "Submission Failed"
  const submissionPassOrFailedIcon = submitSuccess === 201 ? faCheck : faCircleExclamation

  // const submissionLoadingButtonColorChange = submitSuccess === 201 ? 
  return(
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-contain" style={didSubmit ? formBoarderValidated : null}>
          <Typography variant="h5">Submit a quote request.</Typography>
          <TextField 
            error={!!errors.fullName}
            helperText="Enter Full Name" 
            required 
            fullWidth 
            type="text" 
            label="First and Last" 
            name="fullName" 
            value={formData.fullName} 
            margin="normal" 
            onChange={handleChange}
          />
          <TextField
            error={!!errors.isEmail}
            helperText={errors.isEmail || "Example: 000-000-0000"}
            required 
            fullWidth 
            type="email" 
            label="Email" 
            name="email" 
            value={formData.email} 
            margin="normal" 
            onChange={handleChange}
          />
          {errors.email && <div className="email-errors">{errors.email}</div>}
          <TextField 
            error={!!errors.isMobilePhone}
            helperText={errors.isMobile || "Example: 000-000-0000"}
            required 
            fullWidth 
            type="phone" 
            label="Phone 000-000-0000" 
            name="phone" 
            value={formData.phone} 
            onChange={handleChange}
          />
          {errors.phone && <div className="phone-error">{errors.phone}</div>}
          <TextField 
            error={!!errors.address}
            helperText="Please enter an address"
            required 
            margin="normal" 
            fullWidth 
            type="text" 
            label="Address" 
            name="address" 
            value={formData.address} 
            onChange={handleChange}
          />
          <FormControl error={!!errors.serviceType} helperText={errors.serviceType || "Please choose a job type"} margin="normal" fullWidth>
            <InputLabel required id="job-type-select-label">Job Service Type:</InputLabel>
            <Select
              labelId="job-type-select-label" 
              required 
              label="Job Service Type" 
              name="serviceType" 
              value={formData.serviceType || ""} 
              id="serviceType" 
              defaultValue="Select the job type you're requesting" 
              onChange={handleJobRequestOption} 
              colref={selectRef}
            >
              <MenuItem value="">Select the job type you're requesting</MenuItem>
              <MenuItem value="Demolition">Demolition</MenuItem>
              <MenuItem value="Trash Haul">Trash Haul</MenuItem>
              <MenuItem value="Landscaping">Landscaping</MenuItem>
              <MenuItem value="Housekeeping">Housekeeping</MenuItem>
            </Select>
          </FormControl>
          <TextField 
            error={!!errors.request}
            helperText={errors.request || 'Please provide some detail of what you want done.'}
            required 
            fullWidth 
            label="Please describe your idea." 
            name="request"  
            rows={4} 
            multiline 
            value={formData.request} 
            onChange={handleChange} 
          />
          <section className="submit-validate-container">
            <LoadingButton
              variant="contained"
              type="submit" 
              loading={isLoading} 
              loadingIndicator={<CircularProgress size={20} color="inherit" />}
              style={{
                backgroundColor: submitSuccess === 201 ? '#4CAF50' : (submitSuccess ? '#F44336' : '#2196F3'),
                color: 'white', 
              }}
              size="large"
              sx={{width:'100%'}}
            >Submit
            </LoadingButton>
            {formValidate ? 
            <div className="validate-warning">
              <div>Please enter all fields. 
                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: "#FFA500",}} size="2xl" />
              </div>
            </div> : isLoading ? null : null}
            {didSubmit ? 
            <div className={submissionPassOrFailed}>
              <div>{submissionPassOrFailedMessage} 
                <FontAwesomeIcon icon={submissionPassOrFailedIcon} style={textColorValidation} size="2xl" />
              </div>
            </div> : null}
          </section>
        </div>
      </form>
    </>
  )
}

export default Form