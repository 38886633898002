import React, {useEffect, useState} from 'react'
import './footer.css'

const Footer = () => {
  const [year, setYear] = useState(0)
  const date = new Date()
  const getYear = date.getFullYear()
  
  useEffect(() => {
    setYear(getYear)
  },[])
  return(
    <>
      <div className="horizontal-line"></div>
      <footer className="footer">
        <div className="footer-content">
          <div><a href="mailto:chepedemoth@gmail.com">chepedemoth@gmail.com</a></div>
          <div>English: (818)-405-3512</div>
          <div>Spanish: (818)-426-6544</div>
          <p className="chepe-title">Chepe Demo & Trash Haul</p>
          <p>&copy; {year} VND LLC. All rights reserved.</p>
        </div>
      </footer>
    </>
  )
}

export default Footer
