import React from 'react'
import Form from '../Form/form.js'
import {Paper, Typography, Box, Stack} from '@mui/material'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import './contactUs.css'
import personImage from '../..//Images/pic5.jpeg'

const ContactUs = () => {
  return(
    <>
      <Stack
        sx={{background: 'rgba(0, 0, 0, 0.8)'}}
        spacing={2}
        alignItems="center"
      >
        <Paper elavation={5}>
          <Box p={4} sx={{maxWidth: 800, background: 'rgba(255, 255, 255, 0.9)'}}>
            <Form />
            <Typography pt={5} variant='body1'>English: <a href="tel:(818) 405-3512">(818) 405-3512</a></Typography>
            <Typography variant='body1'>Spanish: <a href="tel:(818) 426-6544">(818) 426-6544</a></Typography>
            <Typography variant='body2'>Follow Us  <a href="https://www.facebook.com/profile.php?id=61554954876560"><FontAwesomeIcon icon={faFacebook} size="2xl" style={{color: "#3d79e1"}} /></a></Typography>
            <Typography variant='body2'>Contact Us <a href="mailto:chepedemoth@gmail.com"><FontAwesomeIcon icon={faEnvelope} size="2xl" style={{color: "#3d79e1"}} /></a></Typography>
          </Box>
        </Paper>
      </Stack>
    </>
  )
}

export default ContactUs
