import React from 'react'
import Gallery from '../Gallery/gallery'
import './home.css'

const servicesArray = [
  'Affordable Rates: Competitive pricing for quality service.', 
  'Bilingual Assistance: Fluent in English and Spanish for effective communication.', 
  'Local Experts: Over 20 years serving the community with expertise.', 
  'Proven Experience: Extensive history of successful projects.', 
  'Prompt Service: Swift and efficient response to kickstart your project.'
]

const offersArray = [
  'Demolition',
  'Trash Hauling',
  'Landscaping',
  'House Keeping'
]

const Home = () => {
  return(
    <>
      <main>
        <article className="mission-statement-container">
          <div className="statement-production">
            {/* <h1 className="about-title">About us</h1> */}
            <p className="mission-statement">
              At Chepe Demo, a trusted family-owned team serving Los Angeles for 20+ years, we're all about creating home transformations.  
              From demolitions that open new possibilities to efficient trash hauling, we're here for it. 
              Our expertise covers kitchen, bathroom, drywall, and flooring demolitions, all at competitive rates for swift, quality results. 
              And that's not all - we also enhance residential spaces with our landscaping and housekeeping services.  
              Ready to redefine your living space? Contact us today, and let's turn your vision into reality!"
            </p>
          </div>
        </article>
        <div className="section-divider"></div>
        <div className="we-specialize">
          <h1 className="our-services">Our Services</h1>
        </div>
        <section className="offer-container">
          {offersArray.map((offer, index) => (
            <ul className="unodered-offer" key={index}>
              <li>{offer}</li>
            </ul>
          ))}
        </section>
        <div className="image-services-container">
          <section className="services-container">
            {servicesArray.map((service, index) => (
              <div className="service-list-container" key={index}>
                <ul className="service-ul">
                  <li>{service}</li>
                </ul>
              </div>
            ))}
          </section>
          <Gallery />
        </div>
      </main>
    </>
  )
}

export default Home


