import React, {useState, useEffect} from 'react'
import { 
  Alert, 
  Box,
  Stack, 
  Grid, 
  Typography, 
  ImageListItem, 
  ImageList, 
  Skeleton, 
  Modal, 
  Button
} from '@mui/material'
import Carousel from 'react-bootstrap/Carousel'
import {grey} from '@mui/material/colors'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '1000px',
  maxWidth: '100%',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
const textContainerColor = grey[900]

const Media = () => {
  const [mediaData, setMediaData] = useState([])
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [mediaIndex, setMediaIndex] = useState(0)
  const [selectedImage, setSelectedImage] = useState(null)

  useEffect(()=> {
    fetch(process.env.REACT_APP_PROD_GET_URL)
    .then(response => response.json())
    .then(data => {
      const mediaArray = data.map(media => media.fields.media1)
      const mediaArrayFiltered = mediaArray.filter(media => media !== undefined)
      setIsLoading(true)
      setMediaData(mediaArrayFiltered[0])
    })
    .catch(error => {
      setError(error)
    })
  }, [])

  const handleOpen = (image) => {
    setSelectedImage(image)
    const index = mediaData.findIndex((item) => item.id === image.id)
    setMediaIndex(index >= 0 ? index : 0)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleSelect = (selectedIndex) => {
    setMediaIndex(selectedIndex)
  } 
  return(
    <>
      <Alert className="margue-container" severity="info">
        <Typography variant="body">{!isLoading ? <Skeleton variant="text" /> : 'Images will be categorized based on the type of service. New design coming soon.'}</Typography>
      </Alert>
      <Box sx={{background: textContainerColor, color:'white'}} p={7}>
        <Typography variant="h5">{!isLoading ? <Skeleton variant="text" /> : 'Media Gallery'}</Typography>
        <Typography variant="subtitle">{!isLoading ? <Skeleton variant="text" /> : 'Take the opportunity see some of our work.'}</Typography>
        <Typography><Button onClick={handleOpen}>{!isLoading ? <Skeleton variant="text" /> : 'Open Image Gallery'}</Button></Typography>
      </Box>
      {!isLoading ? 
      (<Grid container justifyContent='center'>
        <Skeleton variant="rectangular" width={1000} height={1000} />
      </Grid>) : (
        <Box p={1}>
          <ImageList className="media-group-container" sx={{ width: "auto", height: 'auto' }} variant="quilted" cols={3} gap={8}>
          {mediaData.map((item) => (
            <ImageListItem key={item.id} cols={item.cols || 1} rows={item.rows || 1}>
              {!isLoading ? 
              (<Grid container justifyContent='center'>
                <Skeleton variant="rectangular" width={500} height={400} />
              </Grid>) : (
                <img
                  style={{cursor: 'pointer'}}
                  // src={`${item.thumbnails.large.url}?w=164&h=164&fit=crop&auto=format`}
                  {...srcset(item.thumbnails.large.url, 121, item.rows, item.cols)}
                  alt={item.filename}
                  loading="lazy"
                  onClick={()=> handleOpen(item)}
                />
              )}
            </ImageListItem>
          ))}
          </ImageList>
        </Box>
      )}
      <Modal 
        open={open} 
        onClose={handleClose}>
          <Stack container sx={style}>
            {/* <Typography variant="h4">Welcome to Media</Typography> */}
            {selectedImage && (!isLoading ? 
              <Skeleton variant="rectangular" width={930} height={500} /> :
              <Carousel touch variant='light' fade style={style} activeIndex={mediaIndex} onSelect={handleSelect}>
                {mediaData.map((med, index) => (
                  <Carousel.Item key={index}>
                    <img src={med.thumbnails.large.url} width='100%' alt={`Media of various jobs ${index}`} />
                  </Carousel.Item>
                )
                )}
              </Carousel>

            )}
          </Stack>
      </Modal>
    </>
  )
}
export default Media