import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardContent, Card, Typography, Skeleton, Avatar, Box } from '@mui/material'
import {grey} from '@mui/material/colors'
import Grid from '@mui/material/Grid'
import { faBroom, faTrashCan, faSunPlantWilt, faHouseCrack } from '@fortawesome/free-solid-svg-icons'
import './services.css'

const Services = () => {
  const [textData, setTextData] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const color = grey[900]

    useEffect(()=> {
      fetch(process.env.REACT_APP_PROD_GET_URL)
      .then(response => response.json())
      .then(data => {
        setLoading(true)
        setTextData(data)
      })
      .catch(error => {
        setError(error)
      })
    }, [])
    
    return(
    <>
      <Box elevation={0} style={{background: color, color: 'white'}}variant="outlined" p={6}>
        <Typography variant="h5">
          {!loading ? 
            <Grid 
              container 
              justifyContent='center'
            >
              <Skeleton variant="text" width={200} height={75}/>
            </Grid> : 'Services'}
        </Typography>
        <Typography variant="body1">
          {!loading ? 
            <Grid 
              container 
              justifyContent='center'
            >
              <Skeleton variant="text" width={400} height={25}/>
            </Grid> : 'Offering a versatile array of services, we cater to four distinct areas.'}
        </Typography>
      </Box>
      {!loading ? (
        <Grid container justifyContent='center'>
          <Skeleton variant="rectangular" width={400} height={500} />
        </Grid>
      ) : (
        <Grid 
          container
          justifyContent="center"
        >
          {textData.map((text) => (
            <Grid item sx={{padding: 2}} key={text.fields.id}>
              <Card sx={{maxWidth: 375, minHeight: 500, background: color, color: 'white' }}>

                <CardContent>
                  <Avatar sx={{width: 75, height: 75, background: '#0073e6', color: 'white'}}>
                    <FontAwesomeIcon
                      icon={text.fields.jobType === 'Trash Hauling' ? faTrashCan 
                        : text.fields.jobType === 'Residential Cleaning Services' ? faBroom 
                        : text.fields.jobType === 'Landscaping' ? faSunPlantWilt 
                        : text.fields.jobType === 'Demolition(Demo)' ? faHouseCrack : ''} 
                      size='2xl' 
                    />
                  </Avatar>
                  <Typography sx={{fontSize: 25, color: '#0073e6'}} color="text.secondary">{text.fields.jobType}</Typography>
                  <Typography variant="body2" sx={{fontSize: 14, textAlign: 'justify'}}>{text.fields.answers}</Typography>
                </CardContent>
              </Card>
            </Grid>
            ))}
        </Grid> 
      )}
    </>
  )
}

export default Services

 
       
    
