/*
Created by Nicholas Santiago
This component has been changed from a state based navigation to a react-router-dom.
*/
import React, {useState, useEffect} from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from '../Components/Header/header'
import Home from '../Components/Home/home'
import Testimonials from '../Components/Testimonials/testimonials'
import ContactUs from '../Components/ContactUs/contactUs'
import Services from  '../Components/Services/services.js'
import Media from '../Components/Media/media.js'
import Footer from '../Components/Footer/footer.js'

const Landing = () => {
  const [currentPage, setCurrentPage] = useState('home')
  const [isUnderConstruction, setIsUnderConstruction] = useState(false)

  const handlePageChange = (page) => {
    setCurrentPage(page)

    if(page === 'testimonials' || page === 'faq') {
      setIsUnderConstruction(true)
    } else {
      setIsUnderConstruction(false)
    }
  }
  return(
    <>
      <Router>
      <Header currentPage={currentPage} handlePageChange={handlePageChange} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/media" element={<Media />} />
        </Routes>
      </Router>
      {/* {currentPage === 'media' && <Media />} */}
      {!isUnderConstruction && <Footer/>}
    </>
  )
}

export default Landing