import React, {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars, faXmark} from '@fortawesome/free-solid-svg-icons'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import {Alert, Box} from '@mui/material'
import companyLogo from '../../Images/IMG_0993.PNG'
import {grey} from '@mui/material/colors'
import './header.css'

const navBackgroundColor = grey[900]

const Header = ({handlePageChange, currentPage}) => {
  const [showMenu, setShowMenu] = useState(false)

  const handleMobileMenuToggle = () => {
    setShowMenu(!showMenu)
  }
  const barAndX = !showMenu ? faBars : faXmark
  return(
    <>
    {/* <Router> */}
      <Box elevation={3} sx={{background: navBackgroundColor}} className="header-container">
        <Link to='/'>
        <section className="logo-container">
          <img src={companyLogo} alt="CompanyLogo" />
        </section>
        </Link>
        <div className="nav-container">
          <ul className="no-dots">
            <li><Link to='/'>Home</Link></li>
            <li><Link to='/services'>Services</Link></li>
            <li><Link to='/media'>Media</Link></li>
            <li><Link to='/contact-us'>Contact Us</Link></li>
            {/* <li onClick={() => handlePageChange('home')}>Home</li> */}
            {/* <li onClick={() => handlePageChange('services')}>Services</li> */}
            {/* <li onClick={() => handlePageChange('media')}>Media</li> */}
            {/* <li onClick={() => handlePageChange('contact-us')}>Contact Us</li> */}
            {/* <li onClick={() => handlePageChange('testimonials')}>Testimonials</li> */}
          </ul>
        </div>
        <div className="header-contact-container">
          <Box width='100%'>
            <Alert variant="filled" severity="info">
              <div><a href="mailto:chepedemoth@gmail.com">chepedemoth@gmail.com</a> English: (818)-405-3512 Spanish: (818)-426-6544</div>
              {/* <div>English: (818)-405-3512</div>
              <div>Spanish: (818)-426-6544</div> */}
            </Alert>
          </Box>
        </div>
{/* Mobile View Nav */}
        <div className={`bar-menu ${showMenu ? 'show-menu' : ''}`} onClick={handleMobileMenuToggle}>
          <div className="bar-container">
            <FontAwesomeIcon icon={barAndX} size="xl" />
          </div>
          <div className={`menu-items ${showMenu ? 'show-menu' : ''}`}>
            <li><Link to='/'>Home</Link></li>
            <li><Link to='/services'>Services</Link></li>
            <li><Link to='/media'>Media</Link></li>
            <li><Link to='/contact-us'>Contact Us</Link></li>
            {/* <div onClick={() => handlePageChange('home')}>Home</div> */}
            {/* <div onClick={() => handlePageChange('services')}>Services</div> */}
            {/* <div onClick={() => handlePageChange('media')}>Media</div> */}
            {/* <div onClick={() => handlePageChange('contact-us')}>Contact Us</div> */}
            {/* <div onClick={() => handlePageChange('testimonials')}>Testimonials</div> */}
          </div>
        </div>
      </Box>
    {/* </Router> */}
    </>
  )
}

export default Header

