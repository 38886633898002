import React, {useState} from 'react'
import Carousel from 'react-bootstrap/Carousel'
import gallery1 from '../../Images/gallery1.jpeg'
import gallery2 from '../../Images/gallery2.jpeg'
import gallery3 from '../../Images/gallery3.jpeg'
import gallery4 from '../../Images/gallery4.jpeg'
import gallery5 from '../../Images/gallery5.jpeg'
import gallery6 from '../../Images/gallery6.jpeg'
import gallery7 from '../../Images/gallery7.jpeg'
import gallery8 from '../../Images/gallery8.jpeg'
import gallery9 from '../../Images/gallery9.jpeg'
import gallery10 from '../../Images/gallery10.jpeg'
import gallery11 from '../../Images/gallery11.jpeg'
import './gallery.css'

const galleryArr = [
  gallery1,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery2,
  gallery3,
  gallery11,
  gallery8,
  gallery9,
  gallery10,
]

const Gallery = () => {
  const [galleryIndex, setGalleryIndex] = useState(0)
 
  const handleSelect = (selectedIndex) => {
    setGalleryIndex(selectedIndex)
  }
  return(
    <>
      <section className="carousel-container">
        <h2 className="gallery-title">Gallery</h2>
        <Carousel activeIndex={galleryIndex} onSelect={handleSelect}>
          {galleryArr.map((gal, index) => (
            <Carousel.Item key={index} interval={3000} className="custom-carousel-item">
              <img className="gallery-img" src={gal} key={index} alt="Gallery Images" />
            </Carousel.Item>
          ))}
        </Carousel>
      </section>
    </>
  )
}

export default Gallery
